const baseUrl = 'https://kyc.ecaasospoc.com/'
export default class QrcodeApi {
    static requestGetKey = (qrcode: string) =>
        fetch(`${baseUrl}api/qrcode/key`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                json: qrcode
            }),
        }).then(result => result.json());
}
