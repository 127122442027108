const designWidth = 1280;
const designHeight = 720;

Number.prototype.w = function () {
  return `${(this / designWidth) * 100}vw`;
};

Number.prototype.h = function () {
  return `${(this / designHeight) * 100}vh`;
};
