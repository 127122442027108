import { Flex, QRCode, Image } from 'antd';
import { FC, useEffect, useState } from 'react';
import QrcodeApi from '../../api/qrcode';

const Login: FC = () => {
  const [grantType, setGrantType] = useState<string | null>(null);
  console.log('grantType', grantType);
  const [qrCodeValue, setQrCodeValue] = useState<string>('-');
  const [status, setStatus] = useState<'active' | 'expired' | 'loading' | 'scanned'>('loading');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const paramStr = decodeURIComponent(window.location.search);
    const urlParams = new URLSearchParams(paramStr);
    const grantTypeFromUrl = urlParams.get('grant_type');
    console.log('grantTypeFromUrl:', grantTypeFromUrl);
    setGrantType(grantTypeFromUrl);
    const handleQrCode = await handleQrCodeCredentialLogin();
    console.log(handleQrCode);
    const encodeQrCode = 'incapture://login?json=' + btoa(encodeURIComponent(handleQrCode));
    const { key } = await QrcodeApi.requestGetKey(encodeQrCode);
    setQrCodeValue(`incapture://login?key=${key}`);
    setStatus('active');
    const intervalTime = 2000;
    setInterval(async () => {
      const response = await fetch('/api/v1/qrcode-credentials', { method: 'post' });
      if (response.ok) {
        if (response.status === 200) {
          const resJson = await response.json();
          if (resJson.status === 'Waiting') {
            console.log('Status is Waiting, continue...');
          } else {
            console.log('Unknown response, continue...');
          }
        } else if (response.status === 204 || response.status === 301 || response.status === 302) {
          const redirectUrl = response.headers.get('Location');
          if (redirectUrl) {
            window.location.href = redirectUrl;
          }
        }
      }
    }, intervalTime);
  }, []);

  const handleQrCodeCredentialLogin = async () => {
    return await (await fetch('/api/v1/qrcode-credentials')).text();
  };

  return (
    <Flex style={styles.background} justify={'center'} align={'center'}>
      <Flex vertical style={styles.content} align={'center'}>
        <Image style={styles.topImage} preview={false} src={require('../../../assets/images/didTopNew.png')} />
        <QRCode
          value={qrCodeValue}
          style={styles.qrcode}
          status={status}
          onRefresh={() => {
            console.log(qrCodeValue);
          }}
        />
        <div style={styles.tips}>请使用InCapture Vault扫描二维码登录</div>
      </Flex>
    </Flex>
  );
};

const styles = {
  background: {
    // backgroundImage: `url(${require('../../../assets/images/bg_login.png')})`,
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center center',
    // backgroundAttachment: 'fixed',
    height: '100vh',
    margin: 0,
    padding: 0,
  },
  content: {
    // width: (340).w(),
    // height: (378).h(),
    // marginLeft: (793).w(),
    // marginTop: (156).w(),
    width: '100%',
    height: '100%',
    // marginRight: 140,
    backgroundColor: '#FFF',
  },
  topImage: {
    width: 400,
    height: 158,
  },
  qrcode: {
    // width: (168).w(),
    // height: (168).w(),
    // marginTop: (78).h(),
    width: 168,
    height: 168,
    marginTop: 43,
  },
  tips: {
    // lineHeight: (17).h(),
    // fontSize: (12).h(),
    // marginTop: (24).h(),
    lineHeight: '17px',
    fontSize: 12,
    marginTop: 24,
    color: '#666666',
  },
};

export default Login;
